'use strict';

angular.module('enervexSalesappApp').factory('AccessoryType', function ($resource) {
	return $resource('/api/accessory-types/:id', {
		id: '@_id'
	},
	{
		update: {
			method: 'PUT'
		}
	});
});
